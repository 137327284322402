// Profile Data & Functions

const moment = require('moment');

export const steps = {
  'LDG': {
    index: 0,
    header: 'Mobees',
    title: 'Carregando',
    subtitle: '...',
    icon: 'loading',
    loading: true,
    cta: {
      toggle: false,
      text: '',
    },
  },
  'ACC': {
    status: 'ACC',
    index: 1,
    header: 'Cadastro',
    title: 'Complete seu perfil',
    subtitle: 'Para oficializar sua candidatura, precisamos de mais algumas informações sobre você.',
    icon: 'profile',
    loading: false,
    cta: {
      toggle: false,
      text: 'Completar perfil',
    },
    completion: 0
  },
  'FDB': {
    status: 'FDB',
    index: 2,
    header: 'Avaliação',
    title: 'Veja sua Avaliação',
    subtitle: '',
    icon: 'evaluation',
    loading: false,
    cta: {
      toggle: false,
      text: '',
    },
    evaluation: {
      score: null,
      feedbacks: []
    }
  },
  'SIG': {
    status: 'SIG',
    index: 3,
    suffix: 'A',
    header: 'Autorização',
    title: 'Termo de Autorização',
    subtitle: 'Para prosseguir, você e o proprietário do veículo devem assinar o Termo de Autorização. *Preencha os dados do veículo para assinar o termo.*',
    icon: 'signature',
    loading: false,
    cta: {
      toggle: false,
      text: '',
    },
    profile: 0,
    envelopes: {
      completion: 0,
      items: {
        driver: {},
        owner: {}
      },
    },
    completion: 0,
  },
  'ENR': {
    status: 'ENR',
    index: 3,
    suffix: 'B',
    header: 'Documentação',
    title: 'Envie seus documentos',
    subtitle: 'Neste passo precisamos que preencha mais informações de perfil, e nos envie os documentos abaixo:',
    icon: {
      'ENR': 'docs',
      'FDD': 'docs-eval',
    },
    loading: false,
    cta: {
      toggle: false,
      text: 'Enviar Documentos',
    },
    profile: 0,
    docs: {
      completion: 0,
      items: [],
    },
    completion: 0,
  },
  'REA': {
    status: 'REA',
    index: 4,
    header: 'Instalação',
    title: 'Estamos quase lá!',
    subtitle: 'Agora só falta liberar a data de instalação. Assim que for liberada, você será notificado. *Fique atento às notificações!*',
    icon: 'ready',
    loading: false,
    cta: {
      toggle: false,
      text: '',
    },
    guidelines: {
      toggle: false,
      seen: false,
    },
    completion: 0,
  },
  'SCH': {
    status: 'SCH',
    index: 5,
    header: 'Instalação',
    title: 'Agende sua Instalação',
    subtitle: 'Chegou a hora! Confira os dias disponíveis no calendário abaixo e confirme seu horário.',
    icon: 'schedule',
    loading: false,
    cta: {
      toggle: false,
      text: '',
    },
    guidelines: {
      toggle: false,
      seen: false,
    },
    completion: 0,
  },
}

export const docs = {
  'doc_cnh': {
    title: 'CNH',
    subtitle: 'Envie um scan ou foto da sua <b>CNH aberta</b>, de forma que seja possível ler todas as informações do documento.',
    example: 'doc_cnh.jpg',
    loading: false,
    success: false,
  },
  'doc_app_perfil': {
    title: 'Perfil Aplicativo',
    subtitle: 'Envie <b>print do seu perfil no aplicativo (Uber, 99, Cabify...)</b> que você mais usa para trabalhar. Siga o exemplo abaixo, de forma que seja possível ver seu nome, foto e nota.',
    example: 'doc_app_perfil.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas1': {
    title: 'Horas Online (semana atual)',
    subtitle: 'Envie print das suas <b>Horas Online na semana atual</b> no aplicativo (Uber, 99, Cabify...) que você mais usa para trabalhar. Siga o exemplo abaixo, de forma que seja possível ver o detalhamento de horas. <br><br>Caso queira, você pode borrar seus ganhos. Não temos nenhum interesse nesta informação. 😉',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas2': {
    title: 'Horas Online (*week-1*)',
    subtitle: 'Envie print das suas <b>Horas Online na semana de *week-1*</b> no aplicativo (Uber, 99, Cabify...) que você mais usa para trabalhar. Siga o exemplo abaixo, de forma que seja possível ver o detalhamento de horas. <br><br>Caso queira, você pode borrar seus ganhos. Não temos nenhum interesse nesta informação. 😉',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas3': {
    title: 'Horas Online (*week-2*)',
    subtitle: 'Envie print das suas <b>Horas Online na semana de *week-2*</b> no aplicativo (Uber, 99, Cabify...) que você mais usa para trabalhar. Siga o exemplo abaixo, de forma que seja possível ver o detalhamento de horas. <br><br>Caso queira, você pode borrar seus ganhos. Não temos nenhum interesse nesta informação. 😉',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_app_horas4': {
    title: 'Horas Online (*week-3*)',
    subtitle: 'Envie print das suas <b>Horas Online na semana de *week-3*</b> no aplicativo (Uber, 99, Cabify...) que você mais usa para trabalhar. Siga o exemplo abaixo, de forma que seja possível ver o detalhamento de horas. <br><br>Caso queira, você pode borrar seus ganhos. Não temos nenhum interesse nesta informação. 😉',
    example: 'doc_app_horas.jpg',
    loading: false,
    success: false,
  },
  'doc_veiculo': {
    title: 'Doc. Veículo – CRLV',
    subtitle: 'Envie scan ou foto do <b>Certificado de Registro e Licenciamento de Veículo aberto e sem o plástico de proteção</b>, de forma que seja possível ler todas as informações do documento.',
    example: 'doc_veiculo.jpg',
    loading: false,
    success: false,
  },
  'doc_veiculo_compra': {
    title: 'Doc. Veículo – Compra/Venda',
    subtitle: 'Envie scan ou foto do <b>Documento de Compra do Veículo aberto e sem o plástico de proteção</b>, de forma que seja possível ler todas as informações do documento.',
    example: 'doc_veiculo_compra.jpg',
    loading: false,
    success: false,
  },
  'doc_residencia': {
    title: 'Comprov. Residência',
    subtitle: 'Envie uma foto do <b>Comprovante de Residência</b>, de forma que seja possível ler nome, endereço e data da correpondência.',
    example: 'doc_residencia.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo1': {
    title: 'Foto Veículo – Dianteira Esquerda',
    subtitle: 'Envie foto com visão da parte <b>Dianteira Direita do seu Veículo</b>, conforme mostra o exemplo abaixo.',
    example: 'doc_foto_veiculo1.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo2': {
    title: 'Foto Veículo – Dianteira Direita',
    subtitle: 'Envie foto com visão da parte <b>Dianteira Esquerda do seu Veículo</b>, conforme mostra o exemplo abaixo.',
    example: 'doc_foto_veiculo2.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo3': {
    title: 'Foto Veículo – Traseira Direita',
    subtitle: 'Envie foto com visão da parte <b>Traseira Direita do seu Veículo</b>, conforme mostra o exemplo abaixo.',
    example: 'doc_foto_veiculo3.jpg',
    loading: false,
    success: false,
  },
  'doc_foto_veiculo4': {
    title: 'Foto Veículo – Traseira Esquerda',
    subtitle: 'Envie foto com visão da parte <b>Traseira Esquerda do seu Veículo</b>, conforme mostra o exemplo abaixo.',
    example: 'doc_foto_veiculo4.jpg',
    loading: false,
    success: false,
  },
  'doc_rg_prop': {
    title: 'CNH/RG Proprietário',
    subtitle: 'Envie scan ou foto da <b>Carteira Nacional de Habilitação (aberta e sem o plástico de proteção) OU RG (aberta e sem o plástico de proteção) do Proprietário do Veículo</b>, de forma que seja possível ler todas as informações do documento.',
    example: 'doc_rg.jpg',
    loading: false,
    success: false,
  },
  'doc_cpf_prop': {
    title: 'CPF Proprietário',
    subtitle: 'Envie foto do <b>CPF do Proprietário do Veículo</b>, de forma que seja possível ler todas as informações do documento.',
    example: 'doc_cpf.jpg',
    loading: false,
    success: false,
  },
}

export default {
  steps,
  docs
}